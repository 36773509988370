<template>
  <!-- Error page-->
  <div>
    <nav-bar />
    <div class="misc-inner p-2 p-sm-3">
      <div class="misc-wrapper container">
        <div class="row text-justify">
          <h2 class="main-title w-100 text-center">About Us</h2>
          <h3>
           DCKAP is one of the world's foremost digital commerce specialists.
          </h3>
          <p>
            
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import store from "@/store/index";
import { $themeConfig } from "@themeConfig";

const NavBar = () => import("@/components/NavBar.vue");

export default {
  components: {
    BLink,
    BButton,
    BImg,
    NavBar,
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg"),
    };
  },
  setup() {
    // App Name
    const { appName, appDarkLogo } = $themeConfig.app;
    return {
      appName,
      appDarkLogo,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}
</style>
